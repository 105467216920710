.whyUs {
  margin-top: 3rem;

  h1 {
    margin: 1rem;
    font-size: 2rem;
    @include breakpoint(large) {
      font-size: 3rem;
    }
  }

  &-grid-wrapper {
    margin: 0 auto;

    .row-one,
    .row-two {
      .col-card {
        padding: 2rem;
        border: 1px solid transparent;
        z-index: 2;

        .img-wrapper {
          margin-bottom: 1rem;
        }
        .card-content {
          color: $small_text;
          display: none;

          @include breakpoint(medium) {
            display: block;
          }
        }

        .title {
          h3 {
            color: $secondary_heading;
          }
        }

        // &:hover {
        //   border: 1px solid #939393;
        //   border-radius: 5px;
        // }
      }
    }
  }
}

.our-story {
  h1 {
    margin-bottom: 1rem;
    font-size: 2rem;
    @include breakpoint(large) {
      font-size: 3rem;
    }
  }

  .story-wrapper {
    font-size: 16px;
    p {
      font-family: $font-text !important;
      text-align: justify;
    }

    .read-more {
      text-align: left;
      @include breakpoint(large) {
        text-align: left;
      }
      .btn {
        color: #e34234 !important;
        font-weight: bold;
        font-size: 16px;
        font-family: $font-body;
      }
    }
  }
  .story-image {
    display: none;
    @include breakpoint(large) {
      display: block;
      img {
        height: auto;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
