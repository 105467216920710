.status {
    margin-bottom: 20px;

    .anticon-info-circle {
       color: blue
    }

    .anticon-exclamation-circle {
        color: orangered
    }

    .anticon-check-circle {
        color: olivedrab
    }
}