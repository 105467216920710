/*.prop_link_pd {
    margin-left: 2rem;
    @include breakpoint(medium) {
        margin-left: 5rem;
       }
}

.cv_pd {
    padding: 0 2rem;
    @include breakpoint(medium) {
        padding: 0 5rem;
    }
}*/
.amenities_new {
    height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 1rem;
}

.share_com {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    flex-flow: row wrap;
    align-content: flex-end;
    gap: 10px;
}

.top_bar {
    display: flex;
    @include breakpoint(medium) {
        display: block;
    }

    .top_bar_ast {
        width: 80%;
        display: block;

        @include breakpoint(medium) {
            width: 100%;
        }
    }

    .top_bar_bst {
        width: 20%;
        display: block;
        align-self: center;
        text-align: center;
        @include breakpoint(medium) {
            display: none;
        }

        .share_btn {
            font-size: 20px;
            border-radius: 360px;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 20px;
            box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.28);

            &:hover, &:active {
                box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.28);
            }
            
        }
        
    }
}

.slider_ {
    display: none;
    @include breakpoint(medium) {
        display: block;
    }
}

.feedback-review {
    padding: 12px;
    border-bottom: 1px solid #e7e7e77a;
    background: #fcfcfc;
    border-radius: 6px;
    margin-bottom: 5px;

    .name-feedback {
        font-size: 0.8rem;
        font-family: 'Open Sans';
        font-weight: bold;
    }

    .added-on-feedback {
        font-size: 0.7rem;
    }

    .date-booked {
        font-size: 0.7rem;
        background: #e3ecff;
        width: fit-content;
        padding: 0px 4px;
        border-radius: 2px;
        font-style: italic;
        margin-bottom: 3px;
    }

    
    
}

.item-logo {
    width: 30px;
    height: 30px;
    img {
        width: 100%;
    }
}

.item-title {
    font-family: 'Open Sans';
    font-size: 15px;
    color: #6a6a6a;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.item-details {
    font-size: 10px;
    font-family: 'Poppins';
}

.item {
    margin-bottom: 10px;
}