.terms {
  background: black;
  color: white;
  padding: 2rem 1.5rem;
  border-radius: 5px;
  margin-top: 5px;

  @include breakpoint(medium) {
    padding: 2rem;
  }

  h2 {
    color: white;
  }

  p {
    width: auto;

    @include breakpoint(medium) {
      width: 22rem;
    }
  }
}
.F_line {
  font-size: 15px;
  font-weight: bolder;

  h3 {
    color: white;
    margin-bottom: 0.5rem;
    font-size: 25px;
    @include breakpoint(medium) {
      font-size: 40px;
    }
  }

  @include breakpoint(medium) {
    font-size: 25px;
    margin-left: 45px;
  }
}

.container_terms {
  background: white;
}

.contents {
  background: white;
  margin-top: 1rem;
  @include breakpoint(medium) {
    margin-top: 0;
  }
}
.ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
  background: white;
}
