.property_search {
  position: relative;
  display: inline-block;

  img {
    height: 100px;
    width: 100%;
    object-fit: cover;
    filter: brightness(0.5);
    @include breakpoint(large) {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
}
.property_search .text {
  left: 40px;
  right: 0;
  text-align: center;
  top: 5%;
  font-family: Arial, sans-serif;
  color: #fff;
  width: 100%;
  font-size: 20px;
  font-weight: 800;
  display: flex;
  position: absolute;
  z-index: 999;
  @include breakpoint(large) {
    font-size: 41px;
  }
}
.property_search .text1 {
  position: absolute;
  z-index: 999;
  left: 40px;
  right: 0;
  top: 42%;
  font-family: Arial, sans-serif;
  color: #fff;
  width: 75%;
  font-size: 10px;
  font-weight: 500;
  display: flex;

  @include breakpoint(large) {
    font-size: 20px;
  }
}
.property_search .text2 {
  position: absolute;
  z-index: 999;
  left: 36px;
  right: 0;
  text-align: center;
  top: 72%;
  font-family: Arial, sans-serif;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  margin-top: -5px;
}
.search_property_bar {
  margin-top: 1rem;
  position: relative;
  z-index: -1;

  .guest-dropdown-listings {
    border: 1px solid #ddd;
    border-radius: 2px;
    align-items: center;
    &:hover {
      border: 1px solid #000;
      border-radius: 2px;
    }

    .adult-count {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 5px 10px;

      p {
        margin-bottom: 0.2rem;
      }
    }

    .guests-count {
      display: flex;
      align-items: center;

      .counnt {
        margin: 2px;
      }
    }
  }
}
.ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px !important;
  line-height: 22px;
  word-wrap: break-word;
  text-align: center;
  font-weight: 700;
}
.slider_input {
  display: flex;
  margin-left: 20px;
}
.slider_range {
  padding-left: 70px;
  padding-right: 65px;
}
.ant-slider-handle {
  border: solid 3px #071117;
}
.Amenties {
  padding-top: 15px;
  margin-left: 44px;
}
.check_name {
  text-align: center;
}
.footer {
  text-align: center;
  display: flex;
  justify-content: space-between;
}
.ant-modal-content {
  width: 330px;
}
.ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
  font-size: medium;
  color: black;
  font-weight: 400;
}
.ant-checkbox-input {
  opacity: 1px;
}
.apply_button {
  color: #fff;
  background-color: #282828;
  border-radius: 5px;
}
.clear_button {
  color: #282828;
  font-weight: bold;
}
.more_btn {
  display: flex;
  margin-left: 41px;
  padding-top: 10px;
}
.property_sorting {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.selections-card-wrapper {
  display: grid;
  grid-gap: 1rem;

  @include breakpoint(medium) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
  }

  @include breakpoint(large) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
  }
}

.empty-listings {
  text-align: center;
  margin: 5rem 0rem 10rem 0rem;
  grid-column: 1/4;
}

.skeleton-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;

  .skel {
    width: 20rem;
  }
}

.pagination {
  margin-top: 3rem;
  text-align: center;
  display: flex;
  justify-content: center;
}
