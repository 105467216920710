.testimonials {
  h1 {
    margin-bottom: 2rem;
    font-size: 2rem;
    @include breakpoint(large) {
      font-size: 3rem;
    }
  }
  .slider {
    .slide {
      .item {
        // background-color: #f1f1f1;
        display: flex;
        align-items: center;
        justify-content: center;
        display: flex;
        align-items: flex-start;
        .testimony-img {
          overflow: hidden;
          display: none;

          @include breakpoint(large) {
            display: block;
          }
          img {
            height: 420px;
            border-radius: 7px;
            width: 100%;
            object-fit: cover;
          }
        }

        .testimony-text {
          padding: 1.5rem;

          @include breakpoint(medium) {
            padding: 15px 5rem;
          }

          .ann {
            position: absolute;
            height: 8rem;
            top: 0px;
            left: 0rem;
            z-index: 99;
            @include breakpoint(medium) {
              top: 0;
              left: 2rem;
            }
          }

          .testimony-description {
            position: relative;
            z-index: 999;
            font-size: 14px;
            font-weight: 100;
            font-family: $font-text;
            letter-spacing: 0.5px;
            color: #6a2925;//$secondary_heading;
            font-style: italic;
            margin-top: 1rem;

            @include breakpoint(medium) {
              font-size: 20px;
            }
          }

          .testimony-user {
            display: flex;
            justify-content: center;
            margin-top: 2rem;

            .user-avatar {
              margin-right: 0.5rem;
              img {
                height: 80px;
                width: 80px;
                object-fit: cover;
                border-radius: 50%;
              }
            }
            .user-title {
              display: flex;
              flex-direction: column;
              justify-content: center;
              .user_text {
                .user_location {
                  align-self: center;
                }

                h3,p {
                  display:inline;
                }
              }

              h3,
              p {
                margin-bottom: 0;
              }
              p {
                color: #484848;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}

.slick-prev:before,
.slick-next:before {
  color: #292929 !important;
  font-size: 25px !important;
}

// .anticon-star {
//   height: 15px;
//   color: #e34234;
// }

// .ant-rate-star:not(:last-child) {
//   margin-right: 3px;
// }

.slick-next {
  display: none !important;
  @include breakpoint(large) {
    display: block;
    right: 22rem !important;
    top: 18.1rem !important;
  }
}

.slick-prev {
  display: none !important;
  @include breakpoint(large) {
    display: block;
    left: 45rem !important;
    top: 18.1rem !important;
    z-index: 1;
  }
}
.slick-track {
  transform: translate3d(-80px, 0px, 0px);
}
.tesstimonail_display {
  white-space: nowrap;
  width: 15rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
