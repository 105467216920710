.container_faq {
  padding: 2rem 0;
  background: white;
}

.content {
  @include breakpoint(medium) {
    margin-top: 0;
  }
  margin-top: 1rem;
}

.collapse .panel {
  margin-bottom: 10px;
  background: white;
}
