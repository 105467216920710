.about_us_row {
  margin-top: 3rem;
}
.about_us_text {
  font-size: 35px;
  font-weight: bold;
  margin-bottom: 0;

  @include breakpoint(medium) {
    font-size: 65px;
  }
}
.about_us_left_row {
  margin-top: 2rem;
  margin-bottom: 3.4rem;
  position: relative;
}
.about_us_left_col {
  margin-bottom: 0rem;
  font-size: 14px;
}
.pen_image {
  img {
    height: 200px;
    margin-bottom: 19px;
    margin-top: -30px;
  }
}

.house_image {
  img {
    height: 200px;
    position: absolute;
    bottom: 19px;
    width: 100%;
    object-fit: cover;
  }
}
.left_col_text {
  text-align: justify;
  margin-top: 1rem;
  font-size: 14px;
  // margin-left: 2rem;
}
.right_image {
  img {
    height: 270px;
    width: 100%;
  }
}
.about_us_row_what_we_do {
  margin-top: 5rem;
}
.right_image_group {
  img {
    height: 400px;
    object-fit: cover;
    width: 100%;
    border-radius: 6px;
  }
}
.group_shopping {
  margin-top: 1rem;
}
.the_team_row {
  margin-top: 3rem;
}
.the_team_image_group {
  img {
    height: 350px;
    width: 100%;
    object-fit: cover;
  }
}
.social_media_row {
  margin-top: 2rem;
}
.social_media_head {
  font-size: 40px;
  font-weight: bold;
}
.three_img {
  img {
    height: 220px;
    object-fit: cover;
    width: 100%;
  }
}
