.counnt {
    margin: 2px;
}

.search_property_bar {
    position: relative;
    z-index: 5;
    border-radius: 4px;
    box-shadow: 0px 3px 11px -5px #00000017;
    -webkit-box-shadow: 0px 3px 11px -5px #00000017;
}

.location-search {
    width: 100%;
    border: none;
    outline: none;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 0px;
    padding-right: 9px;
    background: transparent;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 1);
    background-color: #fff;
    font-feature-settings: "tnum";
    font-variant: tabular-nums;

    &::placeholder {
        color: rgba(0, 0, 0, 0.65);
    }

}


.focus-visible {
    outline: none;
}

.prediction-img-ico {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
}

.ant-spin-dot-item {
    background-color: #707376;
}

.loading {
    width: 100%;
    text-align: center;
    padding: 80px 20px;
}

.selection-card {
    box-shadow: #00000029 0px 0.5px 4px 0px;
}

.searchbox, .datebox, .guests-count {
    padding: 10px;
    background: #fff;
    border-radius: 4px;
    cursor: pointer;

    /*&:hover {
        background: #f3f3f3;
    }*/
}

.guests-count {
    padding: 10px 20px;

    .guests-btns {
        display: flex;
    }
}

.searchbox {
    padding: 10px 20px;
}

.focused-datebox {
    box-shadow: -1px 0px 27px -7px #00000017;
    -webkit-box-shadow:-1px 0px 27px -7px #00000017;
}

.focused-searchbox {
    -webkit-box-shadow: 7px 0px 18px -6px #00000017;
    box-shadow: 7px 0px 18px -6px #00000017;
}

.legend {
    color: black;
    font-weight: bold;
    font-family: 'Poppins';
}

.search-btn, .search-btn:hover, .search-btn:focus {
    background: #171717;
    color: #fff;
}

.search-suggestions {
    position: absolute;
    z-index: 99;
    top: 98px;
    width: 100%;
    border: 1px solid #e9e9e9;
    background: white;
    border-radius: 4px;
    box-shadow: 0px 3px 11px -5px #00000017;
    -webkit-box-shadow: 0px 4px 20px -5px #00000017;
}

.search-suggestions-header {
    font-size: 10px;
    font-weight: bold;
    font-family: 'Poppins';
    padding: 10px 20px;
}

.default-predictions {
    padding: 6px 20px;
    cursor: pointer;
    &:hover {
        background: #fafafa;
    }
}

.prediction-ico {
    width: 35px;
    height: 35px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;

    span {
        font-size: 20px;
        padding: 6px;
        background: #f1f1f1;
        margin: 0px;
        display: flex;
        align-items: center;
        border-radius: 6px;
        border: 1px solid #ebe7e7;
    }
}

.prediction-name {
    color: #000;
    font-size: 16px;
    font-family: 'Poppins';
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.empty-listings {
    text-align: center;
    margin: 2rem 0rem 10rem 0rem;
    grid-column: 1/4;
}

.card-fold {
    text-align: left;
}

.prediction-secondary {
    margin-left: 10px;
    font-size: 12px;
    color: #8a8a8a;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.header-explorer {
    text-align: left;
}

.count-and-tools {
    margin-top: 2rem;
}
