@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&family=Poppins:wght@400;700&family=Roboto&family=Varta:wght@300;400;500;600;700&family=Shadows+Into+Light&display=swap");
//@import url('https://fonts.googleapis.com/css2?family=Varta:wght@300;400;500;600;700&display=swap');
$font-title: "Poppins", sans-serif;
$font-body: "Poppins", sans-serif;
$font-text: "Open sans", sans-serif;
$font-logo-text: "Roboto", sans-serif;
$slider-text: "Shadows Into Light", cursive;

$border-input: 5px;

$box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

$bg_primary: #292929;
$bg_secondary: #282828;
$bg_white: #ffffff;

$title: #000000;
$heading: #292929;
$secondary_heading: #eb5148;
$sub_heading: #282828;
$small_text: #484848;
$white_text: #ffffff;
$black_text: #000000;

$breakpoints: (
  small: 499px,
  medium: 699px,
  large: 999px,
  xlarge: 1399px,
);

@mixin breakpoint($size) {
  $breakpoint-value: map-get($breakpoints, $size);

  @media screen and (min-width: $breakpoint-value) {
    @content;
  }
}
