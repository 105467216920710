//Header for profile
.box_shadow {
  padding: 1.3rem 1rem 0.5rem 1rem;
  z-index: 1;
  background-color: #ffffff;
  box-shadow: 0px 7px 24px -25px #292929;
  right: 0;
}
.card_box {
  padding: 1.8rem;
  z-index: 1;
  background-color: #ffffff;
  box-shadow: 0px 7px 24px -25px #292929;
  right: 0;
}
.profile_header {
  display: flex;
  justify-content: space-evenly;
}

.profile_edit_box {
  text-align: center;
}
.profile_dashboard {
  display: flex;
  align-items: center;
  img {
    height: 15px;
    margin-right: 0.4rem;
  }
}
.profile_dashboard {
  text-align: center;
}

.icons_pro {
  margin-right: 0.4rem;
}

//Body
.meta_icon {
  display: flex;
  justify-content: center;
}
.avatar_img {
  height: 140px;
  width: 140px;
}
.profile_card {
  margin-top: 2rem;
  display: none;
  justify-content: center;
  margin: 0 auto;

 @include breakpoint(medium) {
   display: block;
  }
}
.about_profile {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}
.social_icon {
  display: flex;
  justify-content: center;
}
.social_icon_margin {
  margin-right: 0.7rem;
  color: black;
}
.about_me {
  margin-top: 1.5rem;
}
.title_profile {
  font-size: 25px;
  margin-bottom: auto;

  @include breakpoint(medium) {
    font-size: 33px;
  }
}
.profile_place_name {
  display: flex;
}
.profile_place_name_color {
  color: #eb5148;
  margin-left: 0.5rem;
}
.profile_place_name_head {
  display: flex;
  justify-content: space-between;
}
.card_two {
  margin-top: 2rem;
}
.social_icon_footer {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  list-style-type: none;

  .fa {
    font-size: 25px;
  }
}
.social_icon_margin_footer {
  margin-right: 3rem;
}
.footer_profile {
  display: flex;
  justify-content: center;
  margin-left: -3rem;
}
