.header-wrapper {
  .navBar {
    padding: 0.5rem 2rem;
    display: flex;
    border: none;
    align-items: center;
    justify-content: space-between;
    position: relative;
    // box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

   /* @include breakpoint(medium) {
      padding: 0.3rem 0rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      border: none;
      // border-bottom: 1px solid #ddd;
      align-items: center;
      // margin: 54px; 
      margin-left: 60px;
      margin-right: 70px;
      // box-shadow: $box-shadow;
    } 
    */
    @include breakpoint(large) {
      padding: 0.3rem 0rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      border: none;
      // border-bottom: 1px solid #ddd;
      align-items: center;
      /* margin: 54px; */
      margin-left: 60px;
      margin-right: 70px;
    }

    .hamburger-icon {
      // position: absolute;
      display: block;
      // right: 1rem;

      &-btn {
        background-color: transparent;
        border: 1px solid;
        border-radius: 3px;
        box-shadow: none;
        color: $bg_primary;
      }
      .ant-btn-icon-only {
        font-size: 18px !important;
      }
      /*@include breakpoint(medium) {
        display: none;
      } */
      @include breakpoint(large) {
        display: none;
      }
    }

    .menu_list,
    .menu_btns {
      display: none;
     /* @include breakpoint(medium) {
        display: block;
      } */
      @include breakpoint(large) {
        display: flex;
      }
    }
    .header_logo {
      display: flex;
      margin-top: 15px;
      color: white;
      @include breakpoint(medium) {
        display: flex;
      }
      @include breakpoint(large) {
        display: flex;
      }
      .logo-title {
        font-family: $font-logo-text;
        font-weight: 700;
        // display: none;
        // @include breakpoint(large) {
        display: block;
        // }
      }
      .img_logo {
        margin-right: 0.5rem;
      }
    }
  }
}

.menu_btn {
  margin: 0;
  color: inherit;
  font-size: inherit;

  @include breakpoint(medium) {
    margin: 0rem 0.5rem;
    color: $sub_heading !important;
    font-size: 16px !important;
  }
}


.menu_btn2 {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-weight: 500;
  @include breakpoint(medium) {
    margin: 0rem 0.5rem;
    color: red !important;
    font-size: 16px !important;
  }
}


.nav_link::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #000;
  transition: width 0.3s;
}

.nav_link:hover::after {
  width: 100%;
}

.ant-modal-content {
  width: auto !important;
  @include breakpoint(medium) {
    width: 897px !important;
    margin-left: 0;
  }
}
.login_modal {
  width: auto;
  @include breakpoint(medium) {
    width: 900px !important;
  }

  .modal-inside {
    padding: auto;
    @include breakpoint(medium) {
      padding: 2rem;
    }
  }

  .ant-modal-body {
    padding: 1rem;
    @include breakpoint(medium) {
      padding: 0rem;
    }
  }
}

.my_fonts {
  font-family: $font-body;
}
.anticon-facebook {
  font-size: 22px !important;
}
.login-help {
  display: flex;
  margin-top: 5px;
  justify-content: space-between;
  align-items: baseline;
}

.ant-form-item {
  margin-bottom: 15px !important;
}

.pop_up_otp {
  width: 410px;
  display: flex;
  justify-content: center;
}
.opt_form {
  display: flex;
  justify-content: center;
  margin-top: 42px;
}

.web-user-details {
  display: flex;
  align-items: center;
}

.pop_up_Reset {
  width: 500px;
  display: flex;
  justify-content: center;
  // padding: 20px;
}
.reset_form {
  display: flex;
  justify-content: center;
  margin-top: 42px;
}

.Login_img {
  display: none;
  @include breakpoint(medium) {
    overflow: hidden;
    display: grid;
    justify-content: center;
  }
  img {
    min-height: 100%;
    height: 480px;
    object-fit: cover;
  }
}
.host_with_us_btn {
  border: 1px solid;
  margin-left: 20px;
  border-radius: 5px;
  margin-right: 10px;
}

.covid_with_us_btn {
  border: 1px solid red;
  margin-left: 20px;
  border-radius: 5px;
  margin-right: 10px;
}


.sidebar-content {
  &-buttons {
    display: flex;
    justify-content: space-around;
  }
  &-menu {
    margin-top: 1rem;
    padding: 0 0.5rem;
    font-family: $font-body;
    ul {
      padding-inline-start: 10px !important;
      list-style-type: none;
      text-align: left;

      li {
        margin-bottom: 0.5rem;
        padding: 0.6rem 1rem;
        border-radius: 5px;
        &:hover {
          background-color: #ddd;
        }
      }
    }
  }
  &-host-button {
    margin-top: 2rem;
    display: flex;
    justify-content: center;

    .btn {
      border: 1px solid #292929;
      padding: 0 1.5rem;
    }
  }
  &-footer {
    margin-top: 2rem;
    display: flex;
    list-style-type: none;
    justify-content: space-around;
    // margin-left: 2.5rem;
    .social {
      // margin-right: 2rem;

      .fa {
        font-size: 25px;
      }
    }
  }
}

.menu_list {
.header-wrapper {
  display: flex;
  align-items: center;
  .your-bookings {
    margin-right: 2rem;
    border: 1px solid;
    margin-left: 20px;
    border-radius: 5px;
    height: 100%;
    padding: 7px;
  }
}

}


.ant-drawer-title {
  margin-top: 45px;
  .your-bookings {
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
    padding: 2px;
    border: 1px solid;
  }
}