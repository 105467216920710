.selection-wrapper {
  h1 {
    margin-bottom: 2rem;
    font-size: 2rem;
    @include breakpoint(large) {
      font-size: 3rem;
    }
  }
}

.name_rating {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-size: 16px;
  margin-bottom: 5px;
  h3 {
    white-space: nowrap;
    width: 15rem;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0px;
  }
  p {
    margin-bottom: 0px;
  }
}
.place_name {
  font-size: 12px;
  color: $black_text;
  margin-bottom: 5px;
}
.ant-col-8 {
  display: flex;
  -ms-flex: 0 0 33.33333333%;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
  align-items: baseline;
}
.name_icon {
  font-size: 11px;
  color: $small_text;
  display: flex;
  align-items: baseline;
  margin-left: 3px;
  margin-bottom: 4px;
}
// .card_img_icon {
//   img {
//     height: 14px;
//   }
// }
.ant-card-body {
  padding: 10.2px;
}
.star_icon {
  margin-left: 4px;
  margin-right: 4px;
  color: #e34234;
}

.selections-card-wrapper {
  display: grid;
  grid-gap: 1rem;
  .ant-card-cover {
    img {
      border-top-left-radius: 5px !important;
      border-top-right-radius: 5px !important;
      height: 200px;
      width: 100%;
      object-fit: cover;
    }
  }

  @include breakpoint(medium) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
  }

  @include breakpoint(large) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3.5rem;
    row-gap: 2rem;
  }
}
.about_view_page {
  display: flex;
  justify-content: space-between;
}
.view_btn {
  color: #e34234;
}

.card_img_icon_svg {
  img {
    height: 15px;
  }
}
.h4_name {
  margin: 0;
  font-family: $font-title;
}

.selection-card {
  position: relative;
  cursor: pointer;
  .fav_img {
    .heart_icon {
      position: absolute;
      top: 3%;
      right: 15px;
      cursor: pointer;

      padding: 6px;
      border-radius: 50%;
      z-index: 9;
      img {
        position: relative;
        height: 24px !important;
        width: 26px !important;
        z-index: 99;
      }
    }
  }
  &:hover .selection-card {
    z-index: 999;
  }
}


.imgCaption {
  position: absolute;
    padding-left: 20px;
    padding-right: 10px;
    bottom: 25px;
    color: #fff;
    font-size: 1.2rem;
    width: 100%;
}