.verifying-remarks {
text-align: center;
font-weight: 500;
font-size: 1.3rem;
color: #292929;

.ant-spin-dot-item {
    background-color: #292929;
}

}