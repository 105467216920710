.rating_overlay {
  img {
    height: 200px;
    width: 100%;
    object-fit: cover;
  }
}

.rating_overlay {
  position: relative;
  display: inline-block;
}
.rating_overlay .header_text1 {
  position: absolute;
  z-index: 999;
  left: 220px;
  right: 0;
  text-align: justify;
  top: 38%;
  font-family: Arial, sans-serif;
  color: #fff;
  width: 100%;
  font-size: 35px;
  display: flex;
}
.review {
  background: #f1f1bf;
  padding: 1.5rem;
}

.review_stay {
  margin-top: 3rem;
}
.review_head {
  margin-bottom: -5px;
}
.review_name {
  font-weight: bold;
  font-size: 35px;
  margin-bottom: -7px;
  color: #eb5148;
}
.review_rating {
  text-align: center;
  margin-bottom: -1.5rem;
}
.review_star_rating {
  display: flex;
  justify-content: center;
}
.star {
  margin-right: 0.5rem;
  font-size: 36px;
  margin-top: -10px;
}
.right_col {
  font-size: 40px;
  margin-bottom: 0px;
}
.col_right_svg {
  img {
    height: 350px;
  }
}
.star {
  img {
    height: 35px;
  }
}
.rate {
  height: 25px;
}
