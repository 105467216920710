.hero {
  margin-bottom: 1rem;

  &-credentials {
    padding: 20px;

    .hero-title {
      display: none;
      @include breakpoint(medium) {
        display: block;
      }
    }

    @include breakpoint(large) {
      padding: 30px 40px;
    }
    // border: 2px solid;
    h1 {
      font-size: 30px;
      color: white;

      @include breakpoint(large) {
        color: #292929;
        font-size: 25px;
      }
    }
  }
  &-input-form {
    .input-field {
      max-width: 100%;
      border-radius: $border-input;
      margin-top: 1rem;

      ::placeholder {
        color: $heading !important;
      }
    }

    .search {
      display: flex;
      align-items: center;
    }
    .date-picker {
      width: 100%;
      border-radius: 2px;
      &:hover {
        border: 1px solid #292929;
        border-radius: 2px;
      }
    }
    .guests-dropdown {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &-room-type-buttons {
    margin: 1rem 0rem;
    .rooms-btns-box {
      padding: 0.4rem;
      .room-btn {
        display: grid;
        justify-content: center;
        text-align: center;
        border-radius: 2.5px;
        padding: 0.3rem;
        align-items: center;
        height: 90px;
        color: $heading;
        font-size: 11px;
        outline: solid 1px #292929;
        transition: outline 0.2s ease-in;

        &:hover {
          cursor: pointer;
          outline: 2px solid #292929;
        }

        .room-image {
          margin: 0 auto;
          margin-bottom: 0.5rem;
        }
      }
      .room-btn-selected {
        outline: 2px solid #292929;
      }
    }
  }
  &-search {
    .btn-search {
      width: 100% !important;
      display: flex;
      justify-content: center;
      @include breakpoint(medium) {
        width: 130px !important;
      }
    }
    @include breakpoint(medium) {
      display: flex;
      justify-content: flex-end;
    }
  }

  &-image {
    // border: 2px solid;
    display: none;
    @include breakpoint(large) {
     /* overflow: hidden;
      display: grid;
      justify-content: center;
      border-radius: 0 5px 5px 0; */
      display: block;
    }
    img {
      min-height: 100%;
      height: 480px;
      object-fit: cover;
    }
  }
}

.ant-picker-header {
  background-color: $bg_primary !important;
  color: $white_text !important;
}

.ant-picker-header > button {
  color: $white_text !important;
}

.ant-picker-range-arrow {
  background-color: $bg_primary;
}

.small-screen-bg {
  position: relative;
  img {
    height: 320px;
    width: 100%;
    object-fit: cover;
  }

  .hero-title-small-screen {
    position: relative;
    padding: 0.5rem;
    h1 {
      color: #292929;
      font-size: 1.4rem;
      margin: 0;
      text-align: center;
    }
  }

  @include breakpoint(medium) {
    display: none;
  }

  @media (min-width: 420px) {
    .hero-title-small-screen {
      h1 {
        font-size: 2rem;
      }
    }
  }

}



.guest-dropdown {
  border: 1px solid #ddd;
  border-radius: 2px;
  width: 100% !important;
  align-items: center;
  &:hover {
    border: 1px solid #000;
    border-radius: 2px;
  }

  .adult-count {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 10px;

    p {
      margin-bottom: 0.2rem;
    }
  }

  .guests-count {
    display: flex;
    align-items: center;

    .counnt {
      margin: 2px;
    }
  }
}

.justify-space-between {
  display: flex;
  justify-content: space-between;
}
.room-image {
  img {
    margin-top: 10px;
  }
}
.ant-carousel {
  border-radius: 6px;
}
.overtone {
  box-sizing: border-box;
  width: 100%;
  position: relative;
.img_overtone {
  img {
  width: 100%;
  filter: brightness(0.85);
  border-radius: 8px;
  }
}

.content_overtone {
  position: absolute;
  bottom: 42px;
  left: 45px;
  width: 100%;

  .hd {
    p {
      font-size: 2.1rem;
      color: #ffffff;
      text-shadow: 1px 0px 1px rgba(0, 0, 0, 0.64);
      font-family: $slider-text;
    }
  }

  .pd {
    p {
      color: #ffff;
      margin-bottom: 0;
      font-size: 17px;
      text-shadow: 1px 0px 1px rgba(0, 0, 0, 0.64);
    }
  }

  .vs {
    color: #ffff;
    margin-bottom: 2rem;
    text-decoration: underline;
    margin-top: 0.5rem;
  }
}


}

.slick-dots {
  margin-left: 0 !important;
  margin-right: 0 !important;
  bottom: auto !important;
  li {
    button::before {
      display: none;
    }
  }
}

.slider-btn {
    position: absolute;
    z-index: 99;
    top: 50%;
    margin: 0px 10px;
    width: auto;
    height: auto;
    border: none;
    background: rgba(255, 255, 255,0.28);
    .ico-slider {
      font-size: 29px;
      margin: 4px 8px;
    }
    &:hover {
      background: #ffffff;
      color: #000000;

      .ico-slider {
        color: #000;
      }
    }
}

.right-btn {
  right: 0;
}


.slick-list {
  border-radius: 8px;
}
