.property_view {
  img {
    width: 100%;
    object-fit: cover;
    padding: 4px;
  }
}

.Add_rule {
  margin-top: -15px;
}
.rule_policies {
  display: flex;
}

.yes_no {
  margin-left: 15px;
}
.yes_no_pets {
  margin-left: 45px;
}

.pricing_total {
  display: flex;
  justify-content: space-between;
}

.total-bg {
  padding: 0.3rem;
  background-color: #f1f1f1;
  p {
    color: $heading;
    margin-bottom: 0;
    font-weight: 600;
  }
}

.property_link {
  display: flex;
  margin-top: 8px;
  margin-left: 2rem;

  @include breakpoint(medium) {
    margin-left: 10rem;
  }
}

.containr_view {
  padding: 0 2rem;

  @include breakpoint(medium) {
    padding: 0 10rem;
  }
}

._link {
  a {
    color: #eb5148 !important;
  }
  padding: 0px 5px;
}
.property_name {
  font-weight: bold;
  padding-right: 5px;
}

.list_icons {
  img {
    height: 35px;
  }
}

.rating_icons {
  font-size: 12px;
  margin-top: -5px;
  display: flex;
}
.icon_star {
  color: red;
}

.icon_heart {
  display: flex;
  justify-content: space-between;
  font-size: 24px;
}
.input_btn {
  width: 300px;
}
.guest_input {
  margin-top: 1rem;
}
.price {
  margin-top: 1rem;
}
.btn_search {
  margin-top: 1rem;
  text-align: center;
}

.btn_btn_search {
  color: #fff;
  background: #292929;
  width: 300px;
  border-radius: 6px;
  font-family: $font-body;
}

.footer_card {
  margin-top: 1rem;
  text-align: center;
}
.date_picker {
  margin-top: -12px;
}
.dropDown {
  margin-top: -12px;
}
.amenities {
  margin-top: 1rem;
}
.amenities_display {
  img {
    height: 15px;
  }
}
.amenities_name {
  margin-left: 8px;
  white-space: nowrap;
  width: 5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: -5px;

  @include breakpoint(medium) {
    width: 8rem;
  }
}

.amenities_display {
  display: flex;
}
.review_star {
  display: flex;
  justify-content: flex-end;
}
.stars {
  margin-right: 5px;
}
.Avatar {
  display: flex;
}
.card_body {
  background: #f1f1f1;
}
// .body {
//   margin-top: 18px;
// }
.avatar_name {
  margin-left: 10px;
}
.avatar_date {
  margin-left: 10px;
  margin-top: -10px;
}
.display_star_avatar {
  display: flex;
  justify-content: space-between;
}
.card_space {
  margin-top: 1.3rem;
}
.Map_Image {
  img {
    height: 430px;
    width: 100%;
  }
}

.map-wrapper {
  height: 350px !important;
  width: 100%;

  // z-index: -10 !important;

  @include breakpoint(medium) {
    height: 350px !important;
    width: 550px !important;
  }
}

.guest-dropdown {
  margin-top: 2rem;
  border: 1px solid #ddd;
  border-radius: 2px;
  align-items: center;
  width: 19.5rem;
  margin-top: 1rem;
  &:hover {
    border: 1px solid #000;
    border-radius: 2px;
  }

  .adult-count {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 10px;

    p {
      margin-bottom: 0.2rem;
    }
  }

  .guests-count {
    display: flex;
    align-items: center;

    .counnt {
      margin: 2px;
    }
  }
}
.stickys {
  box-shadow: 2.5px 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  position: -webkit-sticky;
  position: sticky;
  top: 2rem;
  padding: 1rem;
  font-family: $font-text;
  color: $heading;
  display: none;

  @include breakpoint(medium) {
    display: block;
  }
}
.fonts {
  font-family: $font-text;
}
.heart_icons {
  img {
    height: 20px;
    margin-right: 15px;
    cursor: pointer;
  }
}
.yes_no_smoking {
  img {
    height: 20px;
    margin-right: 20px;
  }
}

.containr_view {
  h2 {
    margin-top: 2rem;
  }
  .prop-title {
    margin-bottom: 0;
    white-space: nowrap;
    font-size: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .location {
    margin-top: 0.1rem;
    p {
      color: $small_text;
    }
  }
  .icons {
    display: flex;
    margin-top: 0.5rem !important;
    justify-content: space-around;
    text-align: center;
    background: #f1f1f1;
    padding: 1rem;
    margin-bottom: 1rem;
    @include breakpoint(medium) {
      padding: 1rem 2rem;
    }

    .list_icons {
      p {
        margin-top: 1rem;
        font-size: 10px;
        font-family: $heading;
        font-weight: normal;
        margin-bottom: 0;
      }
      @include breakpoint(medium) {
        font-size: 12px;
      }
      img {
        height: 25px;
      }
    }
  }
}
.BrainhubCarousel__arrowRight {
  position: absolute !important;
  background-color: #000;
  z-index: 99;
  right: 150px;
  border-radius: 50%;
}
.BrainhubCarousel__arrowLeft {
  position: absolute !important;
  color: black !important;
  background-color: black;
  z-index: 999;
  left: 150px;
  border-radius: 50%;
}

.BrainhubCarousel__arrows:hover:enabled {
  background-color: black !important;
}

.property-slider {
  display: none;
  @include breakpoint(medium) {
    display: block;
  }
}

.small-carousal {
  display: block;

  .slider {
    display: flex;

    .images {
      display: flex;
    }
  }

  @include breakpoint(medium) {
    display: none;
  }
}

.view-listing {
  position: relative;
  z-index: 9;

  .bottom-sticky {
    position: fixed;
    bottom: 0;
    z-index: 9999;
    background-color: #fff;
    width: 100%;
    padding: 1rem 2rem;
    border-top: 1px solid #ddd;
    box-shadow: 0px -3px 3px 0px rgba(226, 226, 235, 1);

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .btn-large {
      width: 150px;
      font-size: 15px;
    }

    @include breakpoint(medium) {
      display: none;
    }
  }
}

.prop-desc {
  height: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.read-more {
  color: $secondary_heading;
  cursor: pointer;
}

.css-5mzto9 {
  z-index: 999;
}

.css-c8f9x9 {
  margin-top: 15% !important;
}

.css-v87krr {
  top: 60% !important;
}
