.thank_you_over {
  img {
    height: 200px;
    width: 100%;
    object-fit: cover;
  }
}

.thank_you_over {
  position: relative;
  
}
.thank_you_over .header_text1 {
  position: absolute;
  z-index: 999;
  left: 1rem;
  top: 3rem;
  text-align: justify;
  font-family: Arial, sans-serif;
  color: #fff;
  width: 80%;
  font-size: 18px;
  display: flex;

  @include breakpoint(medium) {
    font-size: 35px;
    left: 115px;
    top: 22%;
    width: 70%;
  }
}

.getting_closer {
  margin-top: -25px;
  margin-right: 64px;
}

.edit_left_col {
  h1 {
    color: #eb5148;
    margin-bottom: -5px;
  }
}
.edit_inner_col {
  display: flex;
  margin-top: 0.2rem;
}

.edit_value {
  margin-right: 1rem;
  margin-left: 1rem;
}
.edit_right_col {
  img {
    height: 290px;
    border-radius: 5px;
  }
}
.edit_inner_col_total {
  display: flex;
  margin-top: 1rem;
}
.edit_total {
  margin-right: 5rem;
  margin-left: 5rem;
}
.total_left {
  margin-bottom: auto;
}
.btn_view_details {
  color: #eb5148;
  margin-top: -5px;
  display: contents;
}
.thank_btn {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
}
.footer_img {
  img {
    height: 1000px;
    width: 100%;
  }
}
