/** Hero Search Box Mobile */



.searchBox {
    margin-top: 20px;
    padding: 0px 30px;

    .DateRangePickerInput__block {
        // gap: 25px;
        display: grid;
    grid-template-columns: 1fr 0.5fr 1fr;
    }

    .boxCaption {
        text-align: center;
        padding-top: 2px;
        padding-bottom: 15px;

        .captionContent {
            font-weight: 700;
            font-size: 1.4rem;
        }
    }

    .searchBoxElement,
    .dateBoxElement,
    .guestBoxElement {
        margin: 0px 10px;
        display: flex;
        align-items: center;
        border: 3px solid #dedcdc;
        border-radius: 38px;
        padding: 8px 4px;
        position: relative;
        gap: 0px 4px;

        .search-suggestions {
            position: absolute;
            z-index: 99;
            width: 100%;
            top: 50px;
            border: 1px solid #e9e9e9;
            background: white;
            border-radius: 4px;
            box-shadow: 0px 3px 11px -5px #00000017;
            -webkit-box-shadow: 0px 4px 20px -5px #00000017;

        }
    }

    .searchBoxIcon {
       // flex-grow: 1;
        padding: 0 4px;
    }

    .searchInputBox {
        width: 80%;
        flex-grow: 8;
        margin-right: 10px;
    }

    .searchInputTypingBox {

        width: 100%;
        border: none;
        outline: none;
        padding-left: 0px;
        padding-right: 9px;
        font-size: 16px;
        line-height: 24px;
        color: #575757;
        font-family: 'Varta', sans-serif;
        font-weight: 500;

        &::placeholder {
            color: #9e9e9e;
            font-family: 'Varta', sans-serif;
            font-weight: 500;
        }



    }

    .focusedMobileSearch {
        border: 3px solid #9e9e9e;
    }

    .element1SearchBox,
    .element2SearchBox,
    .element3SearchBox {
        margin-bottom: 15px;
    }


    .DateInput_input {
        font-weight: 500;
        font-size: 14px;
        /* line-height: 24px; */
        /* color: #484848; */
        color: rgba(0, 0, 0, 0.65);
        background-color: #fff;
        font-feature-settings: "tnum";
        font-variant: tabular-nums;
        width: 100%;
        font-family: "Open sans", sans-serif;
        transition: border 0.3s, box-shadow 0.3s;
        // padding: 0px 13px;
        padding: 0px;
    }

    .DateInput_input__focused {
        border-bottom: 0px solid #ffffff;
    }

    .DateInput {
        width: 100%;
    }

    .DateRangePicker_picker__directionLeft {
        left: -40px !important;
    }

    .searchInputLabel {
       // margin: 0 2px;
        font-size: 16px;
        line-height: 24px;
        color: #9e9e9e;
        font-family: 'Varta', sans-serif;
        font-weight: 500;
    }

    .guestBtn {
        display: flex;
        flex-grow: 2;
        align-items: baseline;
        gap: 8px;
    }

    .count {
        button {
            background: none;
            border: none;
        }

        .guestCount {
            color: #575757;
            font-size: 1.1rem;
        }
    }

    .searchButtonBoxElement {
        margin: 0px 10px;
        display: flex;
        align-items: center;

        border-radius: 38px;
        padding: 8px 1px;
        position: relative;

        button {
            width: 100%;
            background: #292929;
            font-size: 0.9rem;
            color: #fff;
            border: none;
            padding: 8px 4px;
            border-radius: 20px;

        }
    }

}


/** Best Selection **/
a {
    text-decoration: none;
    color: #000;
}

.wrapBestSelections,
.wrapPopularDestinations {

    margin-top: 50px;


    .titleMobileWrap {
        padding: 0px 45px;
        display: flex;
        align-items: baseline;
        justify-content: space-between;

        .captionWrap {
            font-size: 1.15rem;
            font-weight: 800;

        }

        .actionBar {
            button {
                background: none;
                border: none;
            }
        }
    }

    .selectionContent {
        margin-top: 20px;
        overflow-x: auto;
        white-space: nowrap;
        width: 100%;
        display: flex;
        gap: 15px;

        .emptyBox {
            margin: 3px;
        }

        .contentContainer {
            line-height: 16px;

            .locationBox {
                font-size: 0.6rem;
                padding-top: 3px;
            }

            .propertyName {
                font-size: 0.8rem;
                font-weight: 700;
                width: 145px;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .priceNight {
                font-size: 0.75rem;
                font-weight: 700;
            }
        }

        .imageBox {
            width: 150px;
            height: 180px;
            position: relative;

            .placeName {
                position: absolute;
                width: 148px;
                bottom: 5px;
                text-align: right;
                padding: 0px 9px;
                color: #fff;
                font-weight: 900;
                font-size: 1.1rem;
                white-space: normal;

            }

            img {
                border-radius: 16px;
                width: 100%;
                height: 100%;
                object-fit: cover;
                overflow: hidden;
            }
        }
    }
}

/** Popular Destinations */

.wrapPopularDestinations {
    margin-top: 45px;
}

/** Testimonials **/

.sliderCamp {
    position: relative;
    width: 100%;
    margin-top: 45px;


    .slideTestimonial {
        width: 100%;
        padding: 0px 45px;

        .rating {
            text-align: center;
            padding: 30px 0px;
        }

        .userTestimonial {
            display: flex;
            gap: 20px;
            justify-content: center;
            align-items: center;
        }

        .userImg {
            width: 100px;
            height: 100px;
            position: relative;

            img {
                border-radius: 16px;
                width: 100%;
                height: 100%;
                object-fit: cover;
                overflow: hidden;
            }
        }
    }


}


/** Why choose us **/

.whyChooseBox {
    margin-top: 45px;
    padding: 0px 45px;
    line-height: 14px;

    .reasonBox {
        padding: 20px;
    }

    .iconReason {
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
    }

    .titleReason {
        text-align: center;
        font-size: 0.8rem;
        font-weight: 500;
    }

}

/** Our story **/

.ourStorySection {
    margin-top: 45px;
    padding: 0px 40px;

    .ourStoryImg {
        width: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            overflow: hidden;
            border-radius: 16px;
        }
    }

    .ourStoryDescription {
        margin-top: 30px;
        font-size: 0.8rem;
        margin-bottom: 30px;
    }

    .readMoreStory {
        a {
            font-family: 'Varta', sans-serif;
            font-size: 0.9rem;
            font-weight: 600;
        }
    }
}

/** Mobile Property Details **/
/** Slider **/

.sliderWrapper {
    position: relative;

    .slick-list {
        border-radius: 0px;
    }

    .sliderBox {
        width: 100%;

        .dotsSlider {
            visibility: hidden;
            position: absolute;
            bottom: 0px;
            display: flex !important;
            width: 100%;
            overflow: hidden;
            flex-wrap: wrap;
            list-style-type: none;
            outline: 0;
            padding: 0px 20px;
            justify-content: center;
            gap: 2px;

            li button {
                font-size: 0px;
                white-space: nowrap;
                overflow: hidden;
                height: 5px px;
                padding: 3px;
                border-radius: 100%;
                border: 2px solid #b4b2b2;
                background: #2d282869;
                opacity: 0.2;
            }

            .slick-active {
                button {
                    background-color: #ffffff;
                    opacity: 0.6;
                }
            }
        }
    }

    .sliderElement {
        width: 100%;
        position: relative;

        .imgSliderBox {
            width: 100%;
            height: 400px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                overflow: hidden;

            }
        }
    }

    .actionButtons {
        position: absolute;
        top: 15px;
        width: 100%;
        display: flex;
        padding: 0px 30px;
        justify-content: space-between;

        button {
            border-radius: 30px;
            padding: 6px 6px;
            display: flex;
            background: #fffdfd;
            border: 2px solid #524f4f2e;
        }
    }


}

.ant-drawer-title {
    font-family: 'Varta', sans-serif;
    margin-top: 0px;
}

.descriptionWrapper,
.amenitiesWrapper,
.otherDetailsWrapper {

    .descriptionContentWrap,
    .amenitiesContentWrap,
    .otherDetailsContentWrap {
        padding: 0px 30px;
        margin-top: 35px;
        line-height: 20px;

        .descriptionHead {
            padding-bottom: 20px;

            .descriptionTitle {
                font-size: 1.2rem;
                font-weight: 800;
                overflow: hidden;
                width: 100%;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-bottom: 5px;
            }

            .descriptionLocation {
                font-weight: 700;
                font-size: 0.8rem;

            }


        }

        .descriptionLegend,
        .amenitiesLegend,
        .otherDetailsLegend {
            font-size: 1rem;
            font-weight: 800;
        }

        .descriptionDetail {
            padding-bottom: 15px;

            ul {
                margin-top: 5px;
                padding: 0px;
                display: flex;
                gap: 8%;
                font-size: 0.8rem;
                font-weight: 600;
                justify-content: space-between;

                li:first-child {
                    list-style-type: none;
                }
            }



        }

        .descriptionContent {
            padding-top: 5px;

            p {
                font-family: 'Varta', sans-serif;
                font-size: 0.8rem;
            }
        }


        .descriptionContent>p:not(:first-child) {
            display: none;
        }

        .readMoreContent {
            button {
                background: #fff;
                border: none;
                padding: 0;
                font-family: 'Varta';
                font-weight: 700;
                font-size: 0.8rem;
            }
        }

    }

}


.descriptionLongContent {
    p {
        font-family: 'Varta', sans-serif;
        font-size: 0.8rem;
    }
}

.amenitiesList {
    margin-top: 5px;

    .amenitiesContent {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-bottom: 5px;

        .amenitiesIco {
            width: 15px;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                overflow: hidden;
            }
        }

        .amenitiesName {
            font-size: 0.8rem;
            font-weight: 400;
            line-height: 12px;
        }
    }

}

.amenitiesShowAll {
    margin-top: 15px;

    button {
        width: 100%;
        background: #fff;
        border: 1.1px solid rgba(0, 0, 0, 0.65);
        padding: 10px 0px;
        border-radius: 8px;
        font-weight: 800;
        cursor: pointer;

        &:hover {
            background-color: #f5f5f5;
        }
    }


}

.otherDetailsContentWrap {
    .timingInfo {
        display: flex;
        justify-content: space-between;
        font-size: 0.85rem;
        margin-top: 10px;

        .timingStick {
            display: flex;
            gap: 10px;

        }
    }
}

.rulesList {
    margin-top: 8px;

    .ruleElement {
        display: flex;
        gap: 8px;
        font-size: 0.8rem;
        align-items: center;
        margin-bottom: 5px;

        .imgRule {
            width: 15px;

            img {
                width: 100%;
                overflow: hidden;
                object-fit: contain;
            }
        }
    }
}

.additionalRulesContent {
    font-size: 0.8rem;
    font-weight: 300;
    margin-top: 5px;
}

.locationContent {
    margin-top: 5px;

    .topResturantsLabel {
        font-size: 0.8rem;
        font-weight: 700;
        margin-bottom: 5px;
    }

    .topResturantsList {
        line-height: 15px;

        .resturantItem {
            margin-bottom: 8px;
        }

        .resturantName {
            font-size: 0.8rem;
        }

        .resturantDesc {
            font-size: 0.7rem;
        }
    }

    .map_leaf {
        margin-top: 15px;
    }
}


.similarHomes {
    .wrapBestSelections {
        margin: 0;
    }
}


.bookingBox {
    position: fixed;
    bottom: 0px;
    background: rgb(255, 255, 255);
    width: 100%;
    border-top: 2px solid #dfdfdfbd;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    box-shadow: 0 -3px 3px 0 #e2e2eb4a;
    padding-right: 20px;
    z-index: 9999999999;

    .bookingBoxContainer {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: space-between;

        .priceCal {
            font-size: 1.4rem;
            font-weight: 600;
            line-height: 25px;
            display: flex;
            align-items: baseline;
            gap: 15px;

            .editBtn {
                background: none;
                border: none;
                padding: 0;
                font-size: 0.98rem;
            }
        }

        .selectedDate {
            font-size: 0.6rem;

            .setCol {
                text-decoration: underline;
                text-transform: uppercase;
                font-weight: bold;
                cursor: pointer;
            }
        }

        .bookBtn {
            button {
                width: 100%;
                padding: 6px 20px;
                font-size: 0.8rem;
                background: #000;
                color: #fff;
                font-family: 'Varta', sans-serif;
                border: none;
                border-radius: 6px;

                &:disabled {
                    background-color: #aca9a9;
                }
            }


        }
    }
}

.pricingDefinition {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #8080802b;

    .datePickerDefinition {
        display: flex;
        margin-bottom: 20px;
        width: 100%;
    }
}

.guestPickerDefinition {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 90%;

    .guestDetails {
        font-size: 1.1rem;
    }

    .guestDetailsAction {
        display: flex;
        gap: 20px;

        button {
            padding: 0;
            background: none;
            border: none;

        }
    }

    .guestCount {
        font-size: 1.1rem;
    }
}


.errorLog {
    font-size: 0.8rem;
    margin-bottom: 20px;
    color: red;
    text-transform: uppercase;
    border: 1px solid red;
    text-align: center;
    border-radius: 4px;
}

.divCal,
.taxCal,
.totalCal {
    display: flex;
    justify-content: space-between;
    font-size: 0.9rem;
}

.totalCal {
    font-size: 1.1rem;
    font-weight: 800;
    border-radius: 8px;
    margin-bottom: 20px;
}


/** Search Page Mobile **/

.listingsContent {
    margin-top: 40px;
    padding: 0px 40px;

    .listingsLabel {
        font-size: 1.4rem;
        font-weight: 600;
    }

    .buttonListings {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;

        .buttonFilter {
            box-sizing: border-box;
            margin: 0;
            padding: 7.25px 15px;
            border-radius: 2px;
            color: rgba(0, 0, 0, 0.85);
            font-size: 15px;
            font-variant: tabular-nums;
            line-height: 1.5715;
            list-style: none;
            font-feature-settings: 'tnum', "tnum";
            position: relative;
            display: inline-block;
            cursor: pointer;
            border: 1px solid #d9d9d9;
            background: #fff;
        }


    }

}


.listingsCardsWrapper {
    padding: 0px 40px;
    margin-top: 40px;
    bottom: 40px;


    .emptyListings {
        color: #575757;
        text-align: center;
        margin-top: 40px;
        font-weight: 800;
        margin-bottom: 40px;
    }


    .contentContainer {
        line-height: 16px;

        .locationBox {
            font-size: 0.6rem;
            padding-top: 3px;
        }

        .propertyName {
            font-size: 0.8rem;
            font-weight: 700;
            width: 145px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .priceNight {
            font-size: 0.75rem;
            font-weight: 700;
        }
    }

    .imageBox {
        width: 100%;
        height: 200px;
        position: relative;

        .placeName {
            position: absolute;
            width: 148px;
            bottom: 5px;
            text-align: right;
            padding: 0px 9px;
            color: #fff;
            font-weight: 900;
            font-size: 1.1rem;
            white-space: normal;

        }

        img {
            border-radius: 16px;
            width: 100%;
            height: 100%;
            object-fit: cover;
            overflow: hidden;
        }
    }

    .listingsCards {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px 15px;
        justify-content: space-between;
        

    }
}