.filter_btn {
    

    @include breakpoint(medium) {
        width: 100%;
        padding: 6.4px 8px;
    }
}

hr {border-width: 0;
    height: 1px;
    border: 1px solid #ffffff2e;
    border-style: groove;
}


.ant-pagination-options {
    display: none;
}