.steps-action {
  text-align: center;
  margin-right: 50px;
}

.Step_pay {
  width: 570px;
}

//PAYMENTS DETAILS

.pay_detail_input {
  // margin: 0 auto;
  margin-bottom: 1rem;
}

.details_head {
  font-size: 24px;

  @include breakpoint(medium) {
    font-size: 34px;
  }
}

.next_icon {
  width: 70px;
  height: 60px;
  border-radius: 50px;
  color: white;
  background-color: #212529;
}

//RULE

.rule_check {
  display: flex;
  align-items: baseline;
  font-size: 14px;
  text-align: center;
  margin-left: 5px;
}

.rule_condition {
  padding-top: 25px;
  margin-left: 5px;
}

.pay_head {
  /* margin-right: 48px; */
  padding-bottom: 8px;
  color: #212529;
  font-weight: 900;
  font-size: 34px;
  /* margin-left: 68px; */
  padding-left: 71px;
}

// .radio_btn {
//   display: flex;
//   justify-content: space-evenly;
//   align-items: baseline;
//   font-size: 13px;
//   border: solid;
//   width: 265px;
//   margin-left: -153px;
//   margin-top: 30px;
// }
.pay {
  margin-right: 80px;
  margin-top: 10px;
}

.submit_btn {
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  font-size: 20px;
  border: solid;
  width: 265px;
  height: 50px;
  color: white;
  background: #eb5148;
  margin-left: -154px;
  margin-top: 40px;
}

.pay_btn {
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  font-size: 20px;
  border: solid;
  width: 265px;
  height: 50px;
  color: white;
  background: #eb5148;
  margin-left: -154px;
  margin-top: 30px;
}

.Coupon {
  display: flex;
  /* margin-top: -24px; */
  justify-content: flex-start;
  align-items: baseline;
  font-size: 20px;
}

.coupon_code {
  margin-left: 15px;
}

.Coupon_input_wrapper {
  display: flex;
  /* margin-top: -24px; */
  justify-content: flex-start;
  align-items: baseline;
  font-size: 20px;
  padding-bottom: 15px;
}

.coupon_input {
  margin-left: 0px;
  padding-top: 10px;
  /* padding-left: 12px; */
  padding-right: 41px;
  width: 320px;
}

.coupon_btn {
  background: #292929;
  color: #fff;
  height: 40px;
  width: 90px;
  border-radius: 7px !important;
}

//payment Imgage side
.img_card {
  img {
    width: 100%;
    height: 200px;
  }
}

.name_icon {
  text-align: center;
}

.card_img_icon {
  img {
    height: 20px;
  }
}

.dropdown_input {
  padding-top: 20px;
}

.select_input {
  width: 244px !important;
}

.wewew {
  background: red;
}

.payment_detail {
  margin-top: -5px;
}

.pay_dropdown {
  border: 1px solid #ddd;
  border-radius: 2px;
  align-items: center;
  width: 17.5rem;
  margin-top: 1rem;
  margin-left: -16px;

  &:hover {
    border: 1px solid #000;
    border-radius: 2px;
  }

  .adult-count {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 10px;

    p {
      margin-bottom: 0.2rem;
    }
  }

  .guests-count {
    display: flex;
    align-items: center;

    .counnt {
      margin: 2px;
    }
  }
}

.payment-card {
  padding: 1rem;

  .guest-count {
    border: 1px solid #ddd;
    width: 100%;
    margin-top: 1rem;
    padding: 5px 15px;

    p {
      margin-bottom: 0;
    }
  }

  .total {
    margin-top: 1rem;
    padding: 0px 10px;
  }

  .pay-wrapper {
    margin-top: 1rem;

    .payment-btn {
      width: 100%;
    }
  }
}

.required {
  color: #e60404;
}

.contributeBloc {
  background: #11b400;
  width: 100%;
  padding: 4px;
  color: #ffff;
  text-align: center;
  border-radius: 6px;
  margin-bottom: 15px;
}

.modalClimes {
  position: absolute;
  z-index: 100000000;
  width: 100%;
  left: 0px;
  top: 0px;
  height: 100%;
  //background: #00000070;

  .modalOverlay {
    position: absolute;
  z-index: 100;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  height: 200%;
  overflow: hidden;
  background: #00000070;
  }

  .modalContent {
    position: relative;
    top: 52%;
    z-index: 900;
    background: red;
   // margin: 30px;
    max-width: 440px;
    width: fit-content;
    left: 50%;
    transform: translate(-50%, -50%);
  //padding: 20px;
    background: linear-gradient(180deg, rgba(248, 137, 68, 0.2) 0%, rgba(225, 114, 24, 0.05) 100%), #FFFFFF;
  }

  .modalHeadClimes {
    padding: 10px 40px;
    position: relative;
    background: linear-gradient(180deg, rgba(248, 137, 68, 0.2) 0%, rgba(225, 114, 24, 0.05) 100%), #FFFFFF;
    .modalHeadBold {
      font-weight: 700;
      color: #434655;
      text-align: center;
      margin-bottom: 5px;
      position: relative;

    }
  }

  .closeBtnMdl {
    position: absolute;
    right: 4px;
    width: 30px;
    top: 4px;
    text-align: center;
    background: #eacfbd;
    border-radius: 4px;
    cursor: pointer;
  }

  .modalCol {
    background: #FFFFFF;
    padding: 2px 8px;
    border-radius: 4px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .modalLineContent {
    color: #878990;
    font-size: 0.75rem;

  }

  .modalIcon {
    width: 35px;
    img {
      width: 100%;
    }
  }

  .amreExpContent {
    p {
      color: #878990;
      font-size: 0.75rem;
      //text-align: justify;
    }

    .highlightSmall {
      font-size: 0.6rem;
     
    }
  }

  .amreExp {
    background: #FFFFFF;
    padding: 15px 20px;

    .modalIcon {
      width: 100%;
    }
  }

  .contribution {
    background: #60B246;
    padding: 10px 40px;
    text-align: center;
    font-weight: 700;
    color: #FFFFFF;
    font-size: 0.75rem;
  }

  .howItWorksExp {
    padding: 10px 30px;

    .howCap {
      color: #434655;
      font-size: 0.85rem;
      font-weight: 700;
      padding-bottom: 4px;

    }

    .defineCap {
      color: #878990;
      font-size: 0.75rem;
      padding-bottom: 8px;
    }
  }


}

