.host_wrapper {
  margin-top: 1rem;
}
.header_host {
  text-align: center;
  margin-bottom: 2rem;
  @include breakpoint(medium) {
    text-align: left;
  }
}
.host_head_title {
  margin-top: 0.8rem;
  font-size: 25px;
  font-weight: bold;

  @include breakpoint(medium) {
    font-size: 35px;
  }
}
.Host {

  img {
    display: none;
    height: 400px;
    width: 100%;
    object-fit: cover;
    @include breakpoint(medium) {
      display: block;
    }  
  }
}
.input_host {
  margin-bottom: 0.5rem;
}

.btn_getStarted {
  text-align: center;
}
.btn_btn_start {
  color: white;
  background-color: #0c6170;
  border-radius: 5px;
}
.about_host_row {
  margin-top: 3rem;

  @include breakpoint(medium) {
    margin-top: 10rem;
  }
}
.about_host_col {
  text-align: justify;
}
.earn_host_row {
  margin-top: 1.5rem;
  @include breakpoint(medium) {
    margin-top: 3rem;
  }

  .earn_host_h1 {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 0;

    @include breakpoint(medium) {
      font-size: 75px;
    }
  }
}
.earn_left_row {
  margin-top: 1rem;
  margin-bottom: 3.4rem;
  position: relative;
  @include breakpoint(medium) {
    margin-top: 2rem;
  }
}
.earn_left_col {
  margin-bottom: 0rem;
  width: 20rem;
}
.earn_right_col_home {
  margin-top: 2rem;
  color: #0c6170;
  display: flex;
  justify-content: center;
  @include breakpoint(medium) {
    margin-top: 0;
  }
}
.earn_right_col_booking {
  color: #0c6170;
}
.earn_right_col_5str {
  color: #0c6170;
  margin-bottom: -0.8rem;
}
.earn_right_col_div {
  color: #0c6170;
  text-align: center;
}
.earn_right_col_review {
  color: #0c6170;
  margin-left: 3.8rem;
}
.human_svg {
  display: none;
  position: absolute;
  left: 25rem;
  top: 52px;
  img {
    height: 210px;
    display: flex;
    align-items: center;
  }

  @include breakpoint(medium) {
    display: block;
  }
}

.cover_img {
  img {
    height: 35px;
    margin-bottom: 0.5rem;
  }
}
.col_padding {
  padding-right: 5px;
}
.cover_name {
  color: #0c6170;
  font-weight: bold;
  margin-bottom: 0px;
}
.cover_row {
  margin-bottom: 1rem;
}
.home_owner_row {
  background: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 1.8rem;
}
.Home_owner {
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 1.8rem;
}
.carrousel_image {
  height: 195px;
  align-items: center;
  margin-left: 1.2rem;
  margin-right: 1.2rem;
  border-radius: 50%;
}
.carrousel_image1 {
  height: 110px;
  align-items: center;
  margin-left: 1.2rem;
  margin-right: 1.2rem;
  border-radius: 50%;
}
.middle {
  width: 100px;
  height: 100px;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    align-items: center;
    border-radius: 50%;

    @include breakpoint(medium) {
      //height: 195px;
    }
  }
}
.middle_next {
  img {
    height: 110px;
    align-items: center;
    margin-left: 1.2rem;
    margin-right: 1.2rem;
    border-radius: 50%;
  }
}
.corner {
  img {
    height: 84px;
    align-items: center;
    border-radius: 50%;
  }
}
.Home_owner_name {
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-top: -1rem;
}
.about_owner {
  display: flex;
  color: #0c6170;
  font-weight: bold;
}
.become_host {
  margin-top: 3rem;
}
.host_image {
  img {
    height: 350px;
    width: 100%;
  }
}
.become {
  display: flex;
}
.host_overlay {
  position: relative;
  display: inline-block;
}
.host_overlay .header_hoster {
  position: absolute;
  z-index: 999;
  left: 1rem;
  text-align: center;
  top: 31%;
  font-family: Arial, sans-serif;
  color: #fff;
  width: 100%;
  font-size: 24x;
  display: flex;

  @include breakpoint(medium) {
    font-size: 41px;
    left: 115px;
  }
}

.host_overlay .become {
  position: absolute;
  z-index: 999;
  left: 1rem;
  right: 0;
  top: 50%;
  font-family: Arial, sans-serif;
  color: #fff;
  font-size: 20px;
  display: flex;
  margin-top: -5px;
  @include breakpoint(medium) {
    left: 115px;
    top: 60%;
  }
}

.corner {
  background-size: cover !important;
}
