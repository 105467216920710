.Frequently_ask {
  background: #eb5148;
  color: white;
  padding: 10px;
  border-radius: 5px;

  width: 100%;
}
.First_line {
  font-size: 35px;
  font-weight: bolder;
  margin-left: 45px;
}
.second_line {
  font-size: 20px;
  font-weight: bolder;
  margin-left: 45px;
}
.topics_wrapper {
  margin-top: 2rem;
  display: flex;
}

.to_be_browse {
  margin-left: 15px;
  background: #f4f4f4;
  text-align: center;
  padding: 1rem;
}

.find {
  margin-top: 0rem;
  display: flex;
  justify-content: center;
}

.contact_svg {
  text-align: center;
}
.contact_wrapper {
  margin-top: 1rem;
}
.svg {
  img {
    height: 350px;
    width: 100%;
  }
}

.map {
  img {
    height: 500px;
    width: 100%;
    object-fit: cover;
  }
}

.contact_person {
  display: flex;
  justify-content: space-evenly;
  color: #292929;
  font-size: larger;
}
.contact_Icon {
  height: 45px !important;
  display: flex !important;
  justify-content: space-evenly;
  color: tomato;
  font-size: 25px;
  margin-right: 50px;
  margin-bottom: 10px;
  width: 45px;
  border: 2px solid #ddd !important;
  border-radius: 50%;
  background: #ddd !important;
  align-items: center;
  margin-left: 18px;
}

.contact_parent {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
  margin-top: 10px;
}
.email_icon {
  height: 45px !important;
  display: flex !important;
  justify-content: space-evenly;
  color: tomato;
  font-size: 25px;
  margin-right: 50px;
  margin-bottom: 10px;
  width: 45px;
  border: 3px solid #ddd !important;
  border-radius: 50%;
  background: #ddd !important;
  align-items: center;
  margin-left: 30px;
}

.contact_parent {
  img {
    height: 52px !important;
    display: flex !important;
    justify-content: space-evenly;
    color: tomato;
    font-size: 20px;
    margin-right: 50px;
    margin-bottom: 10px;
    width: 52px;
    border: 3px solid #ddd !important;
    border-radius: 50%;
    background: #ddd !important;
    align-items: center;
    margin-left: 30px;
  }
}
.contact_us_head {
  font-size: 35px;
  font-weight: bold;
}
.img_svg {
  img {
    height: 280px;
    width: 100%;
  }
}
.faqs {
  img {
    // height: 200px;
    width: 100%;
    border-radius: 10px;
  }
}
