.DateRangePickerInput__withBorder {
    $p: &;

    border-radius: 2px;
    border: 1px solid #d9d9d9;
    -webkit-transition: border 0.3s, -webkit-box-shadow 0.3s;
    transition: border 0.3s, -webkit-box-shadow 0.3s;
    transition: border 0.3s, box-shadow 0.3s;
    transition: border 0.3s, box-shadow 0.3s, -webkit-box-shadow 0.3s;

    &:focus {
        border-color: #40a9ff;
    }

    &:hover {
        border-color: #292929;
    }

 
}


.DateInput_input {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* color: #484848; */
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    font-variant: tabular-nums;
    width: 100%;
    padding: 6.5px 11px 6.5px;
    font-family: "Open sans", sans-serif;
    -webkit-transition: border 0.3s, -webkit-box-shadow 0.3s;
    transition: border 0.3s, -webkit-box-shadow 0.3s;
    transition: border 0.3s, box-shadow 0.3s;
    transition: border 0.3s, box-shadow 0.3s, -webkit-box-shadow 0.3s;

    &::placeholder {
        color: rgba(0, 0, 0, 0.295);
    }
}

.DateInput_input__focused {
    border-bottom: 2px solid #1890ff;
} 

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    background: #1890ff;
    border: 1px double #1890ff;
    color: #fff;
}

.CalendarDay__selected_span {
    background: #e6f7ff;
    border: 1px double #e6f7ff;
    color: rgba(0, 0, 0, 0.65);
    -webkit-transition: border 0.3s, -webkit-box-shadow 0.3s;
    transition: border 0.3s, -webkit-box-shadow 0.3s;
    transition: border 0.3s, box-shadow 0.3s;
    transition: border 0.3s, box-shadow 0.3s, -webkit-box-shadow 0.3s;
}

.CalendarDay__selected_span:hover {
    background: #d1e1e9;
    border: 1px double #e6f7ff;
    color: rgba(0, 0, 0, 0.65);

}

.CalendarMonth_caption {
    font-family: "Open sans", sans-serif;
}

.CalendarDay__default {
    border: none;
    
}

.DateRangePickerInput__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.DateRangePicker_picker {
    z-index: 99999;
}