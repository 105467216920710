.filter-section {
  padding: 0;
  .left,
  .middle {
    border: none;
    padding: 1rem;

    @include breakpoint(medium) {
      border: none;
      border-right: 1px solid #ddd;
      padding: 1rem;
    }
  }

  .right {
    padding: 1rem;
  }

  .left {
    .price-diff {
      display: flex;
      align-items: baseline;
      justify-content: space-between;

      input {
        width: 6.5rem;
      }
    }
  }
  .middle {
    height: 415px;
    overflow: scroll;
    .amenities-section {
      display: flex;
      justify-content: space-between;
      .left-col,
      .right-col {
        overflow: hidden;
        .ant-checkbox-wrapper {
          display: flex;
          align-items: center;
        }
        p {
          font-size: 13px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 8.5rem;
          margin-bottom: 0;

          @include breakpoint(medium) {
            width: 14rem;
          }
        }
      }
    }
  }
  .right {
    height: 415px;
    overflow: scroll;
    .roomType-section {
      display: flex;
      justify-content: space-between;
      .left-col,
      .right-col {
        overflow: hidden;
        .name {
          font-size: 14px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.filter_modal {
  width: 1100px !important;
  .modal-body {
    width: auto;

    @include breakpoint(medium) {
      width: 70rem !important;
    }
  }
  .ant-modal-body {
    padding: 0;
  }
}
