.banner-wrapper {
  background: #f1f1f1;
  color: $black_text;
  padding: 1.8rem;
  margin-bottom: 2rem;
  position: relative;
  z-index: 2;

  @include breakpoint(large) {
    padding: 1.8rem 5rem;
  }

  .banner-text {
    // margin-bottom: 1rem;
    p {
      font-size: 16px;
      color: $heading;
      margin-bottom: 0.5rem !important;

      @include breakpoint(large) {
        font-size: 20px;
      }
    }

    h1 {
      color: $black_text;
      text-align: center;
      @include breakpoint(small) {
        text-align: left;
      }
    }
  }

  .baner-image {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    @include breakpoint(medium) {
      margin-top: 1rem;
    }
  }
}
