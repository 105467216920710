.footer-container {
  padding: 3rem 0rem 0rem 0rem;
  font-family: $font_body;
  margin-top: 30rem;
  display: none;

  @include breakpoint(medium) {
    display: flex;
    justify-content: space-around;
  }

  .logo {
    padding-left: 3rem !important;
  }
  .col {
    padding: 1rem;
    margin: 0rem;

    .sub_menu {
      h3 {
        color: white;
        text-transform: uppercase;
        font-size: 14px;
      }

      list-style-type: none;
      color: $white_text;
      text-transform: capitalize;

      li {
        margin-top: 0.8rem;
      }
    }
  }
  .social {
    padding: 0.5rem 3rem;
    display: grid;
    .content {
      h2 {
        color: white;
      }
      .text {
        color: white;
      }
      .subscribe-form {
        display: flex;

        input {
          width: 18rem;
          margin-right: 1rem;
        }
      }
    }

    h3 {
      text-transform: uppercase;
      color: #717171;
      font-size: 14px;
      //margin: 1rem 0rem 0.5rem 1rem;
    }
    ul {
      padding-inline-start: 0px;
    }
    .social_icons {
      display: flex;

      & .first-social {
        margin: 0rem;
        margin-right: 1rem;
      }

      li {
        list-style-type: none;
        margin: 0rem 1rem;

       
      
        .fa {
          color: $white_text;
          font-size: 24px;
        }
      }
    }
    @include breakpoint(large) {
      padding: 1rem 0rem;
    }
  }
  .last_line {
    color: #f7f7f7;
    text-align: center;
  }
}

.arrow {
  transform: rotate(-90deg);
  width: 22px;
}
.arrow_right {
  width: 22px;
}
.btn_arrow_right {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn_arrow {
  width: 40px;
  height: 40px !important;
}
.footer_logo {
  height: 45px;
  margin-left: 28px;
}
.footer-wrapper {
  position: relative;
  margin-top: 5rem;
  .house-img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    position: absolute;
    bottom: 0;

    @include breakpoint(medium) {
      position: absolute;
      bottom: 0;
      height: 675px;
      width: 100%;
      object-fit: cover;
    }
  }
}

.scroll-top-footer {
  position: absolute;
  left: 46%;
  // z-index: 99;
  top: -15rem;

  @include breakpoint(medium) {
    left: 48%;
    z-index: 99;
    top: 0;
  }
}

.small-footer {
  display: flex;
  text-align: center;
  margin-top: 20rem;
  p {
    color: white;
    margin-bottom: 0;
  }

  @include breakpoint(medium) {
    display: none;
  }

  .footer_logo {
    height: 45px;
    margin-left: 0 !important;
  }

  ul {
    padding-inline-start: 0px;
    margin-top: 2rem;
  }
  .social_icons {
    display: flex;
    justify-content: center;

    h2 {
      font-size: 16px;
    }
    


    li {
      list-style-type: none;
      margin: 0rem 1rem;
     
      

      .fa {
        color: $white_text;
        font-size: 20px;
      }
    }
  }
}
.facebook_login {
  margin-top: 10px;
    .kep-login-facebook {
      width: 100%;
      border-radius: 6px;
      font-size: 12px;
  }
}


