
.overlapm {

.overlow {
    position: absolute;
    background: white;
    bottom: 25px;
    right: 20px;
    font-size: 18px;
    width: 30px;
    text-align: center;
    border-radius: 5px;
    opacity: 0;
    transition-timing-function: ease-in-out;
    transition: opacity .7s;
}

&:hover {
    .overlow {
        opacity: 1;
    }
}

}

.SRLImage {
    top: 60% !important;
}