.box_shadow_edit {
  padding: 1rem;
  z-index: 1;
  background-color: #ffffff;
  box-shadow: 34px 0px 30px -15px rgba(0, 0, 0, 0.75);
  right: 0;
}
.profile_header_edit {
  display: flex;
  justify-content: space-evenly;
}
.profile_edit_dashboard {
  text-align: center;
}
.profile_edit_dashboard {
  img {
    height: 15px;
    margin-right: 0.4rem;
  }
}
.left_row {
  display: flex;
  justify-content: space-evenly;
}
.margin_auto {
  margin: 0 auto;
}
.left_col {
  text-align: center;
}
.avatar_image {
  height: 120px;
  width: 120px;
}
.edit_profile_name {
  margin-top: 1rem;
}
.social_account_head {
  margin-left: 5rem;
  margin-top: 2.5rem;
}
.social_account {
  display: flex;
}
.margin_social_app {
  margin: 0 auto;
}
