.culture_title {
  font-size: 35px;
  margin: 0 auto;

  @include breakpoint(medium) {
    font-size: 75px;
  }
}
.first_row {
  margin-top: 2rem;
}
.culture_img {
  img {
    height: 350px;
    width: 100%;
    object-fit: cover;
  }
}
.culture_team {
  position: relative;

  img {
    width: 100%;
    object-fit: cover;
    background-image: linear-gradient(
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(http://www.stadtteilschule-oejendorf.de/Unterricht/files/stacks_image_2936.png);
  }
  p {
    position: absolute;
    color: white;
    left: 50%;
    top: 50%;
    font-size: 12px;
    transform: translate(-50%, -50%);
    @include breakpoint(medium) {
      font-size: 22px;
    }
  }
}

.team-message {
  background-color: #f7f7f7;
  padding: 2rem;
  position: relative;

  @include breakpoint(medium) {
    padding: 4rem 7rem;
  }

  img {
    height: 100px;
    position: absolute;
    left: 1rem;
    top: 1rem;
    @include breakpoint(medium) {
      left: 195px;
      top: 50px;
    }
  }

  h3 {
    padding: 0 2rem;
    font-size: 16px !important;
    margin-bottom: 2rem;

    @include breakpoint(medium) {
      padding: 0 8rem;
    }
  }
  p {
    color: #292929;
    line-height: 2;
  }
}

.ant-col-offset-1 {
  margin-left: 0;
  @include breakpoint(medium) {
    margin-left: 4.16666667%;
  }
}

.career_img {
  height: 250px !important;
    width: 100% !important;
    object-fit: contain !important;
}

.job {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e6e6e6;

  h2 {
    color: #4b4b4b;
    cursor: pointer;
  }
}

.careers {
  background: #bc0000;
  border: none;
  color: #fff;
  padding: 5px 12px;
  cursor: pointer;
  margin-top: 25px;
}