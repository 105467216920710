h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  font-family: $font-title;
}



p {
  font-family: $font-body;
  margin-top: 0;
  margin-bottom: 0.3rem;

}

.text {
  color: $bg_primary;
  font-family: $font-body;
}

.text-light {
  color: $white_text;
}

.text-dark {
  color: #000000;
}

.shadow {
  box-shadow: $box-shadow;
}

.text-center {
  text-align: center;
}

.mx-auto {
  margin: 0 auto;
}

.mb-1 {
  margin-bottom: 1rem;
}
// Primary button css Black color-------
.btn-primary {
  background-color: $bg_primary;
  color: $white_text;
  border: transparent;
  font-family: $font-body;
  width: 130px;
  border-radius: $border-input;
}

.btn-primary:hover {
  background-color: $small_text;
  color: $white_text;
  border: #282828;
}

.btn-primary:focus {
  background-color: $small_text;
  color: $white_text;
  border: #000000;
}

.btn-large {
  width: 100%;
}

.border {
  border: 1px dashed;
}

//container padding ----------

.container {
  padding: 20px;

  @include breakpoint(medium) {
    padding: 40px;
  }

  @include breakpoint(large) {
    padding: 2rem 10rem;
  }
}
.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}
.ant-input:focus {
  border-color: #292929;
  outline: 0;
  box-shadow: none;
  // color: #292929;
}
.ant-input:hover {
  border-color: #292929;
  outline: 0;
  box-shadow: none;
}
.ant-input-password:focus {
  border-color: #292929;
  outline: 0;
  box-shadow: none;
  // color: #292929;
}
.ant-input-password:hover {
  border-color: #292929;
  outline: 0;
  box-shadow: none;
}

.ant-select-selector:focus {
  border-color: #292929;
  outline: 0;
  box-shadow: none;
  // color: #292929;
}
.ant-select-selector:hover {
  border-color: #292929;
  outline: 0;
  box-shadow: none;
}

.ant-picker-range:focus {
  border-color: #292929;
  outline: 0;
  box-shadow: none;
  // color: #292929;
}
.ant-picker-range:hover {
  border-color: #292929;
  outline: 0;
  box-shadow: none;
}

.ant-picker-input:focus {
  border-color: #292929;
  outline: 0;
  box-shadow: none;
  // color: #292929;
}
.ant-picker-input:hover {
  border-color: #292929;
  outline: 0;
  box-shadow: none;
}

.ant-picker-large:focus {
  border-color: #292929;
  outline: 0;
  box-shadow: none;
  // color: #292929;
}
.ant-picker-large:hover {
  border-color: #292929;
  outline: 0;
  box-shadow: none;
}

.rupee {
  font-family: sans-serif !important;
  font-size: 13px;
}

.css-1b7lsh2 {
  height: 90px !important;
}

.covid {
  padding: 1rem;

  @include breakpoint(medium) {
    padding: 3rem;
  }
}


.mobile-home-segment, .mobileSegment {
  font-family: 'Varta', sans-serif;
}

