.destination-wrapper {
  h1 {
    margin-bottom: 2rem;
    font-size: 2rem;
    @include breakpoint(large) {
      font-size: 3rem;
    }
  }
  
  .row-wrapper {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    overflow-x: auto;
    grid-gap: 10px;

    .col-place {
      display: grid;
      justify-content: center;
      width: 100%;

      img {
        position: relative;
        box-shadow: inset 0 50px 50px #000000;
      }
      .place-title {
        position: absolute;
        bottom: 0.3rem;
        left: 3rem;
        font-size: 15px;
        font-family: $font-body;
        color: $white_text;
      }
    }
  }
}
.row-wrapper-label {
  grid-template-columns: repeat(4, 1fr) !important;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
